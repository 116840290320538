<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Febrero 2025                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <v-main style="padding: 0 1rem !important;">

    <!-- filtros de busqueda y botón para agregar un registro -->
    <div class="d-flex pt-3">
      
      <v-text-field class="me-2" outlined dense hide-details label="Código cliente"
        v-model="buscarCliente">
      </v-text-field>

      <v-text-field class="me-2" outlined dense hide-details label="Código DCI"
        v-model="buscarCodigoDci">
      </v-text-field>
      
      <v-text-field class="me-2" outlined dense hide-details label="Producto Stone"
        v-model="buscarProductoStone">
      </v-text-field>

      <v-text-field class="me-2" outlined dense hide-details label="Producto cliente"
        v-model="buscarProductoCliente">
      </v-text-field>

      <v-tooltip left color="success">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small class="ms-2" fab color="success" v-bind="attrs" v-on="on"
            @mousedown.prevent="dialog = true">
            <v-icon> add </v-icon>
          </v-btn>
        </template>
        <span>Agregar</span>
      </v-tooltip>
    </div>

    <!-- dialogo para crear un registro -->
    <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition" max-width="49.7rem">
      <v-card>
        <v-tabs v-model="opciones" background-color="blue darken-4" dark class="v-tabs--fixed-tabs">
          <v-tabs-slider color="yellow" class="v-tabs"></v-tabs-slider>
          <v-tab :disabled="leyendoArchivo" style="margin-right: 35px; margin-left: 17px !important;">Agregar homologación uno a uno</v-tab>
          <v-tab style="margin-right: 25px; margin-left: 17px !important;">Agregar homologaciones masivas</v-tab>
        </v-tabs>

        <!-- formulario crear un registro -->
        <v-tabs-items v-model="opciones">
          <!-- Uno a uno -->
          <v-tab-item>
            <v-card-text class="pa-5 pb-3 pt-3">
              <v-form ref="nuevaRelacion">

                <!-- Campo para buscar y elegir un cliente -->
                <div>
                  <v-autocomplete ref="refClienteAdd" v-model="homologacionCodClie" label="Cliente" outlined dense :items="clientes" 
                    :menu-props="menuPropsCliente" @keydown="tabulador($event, 'homologacionCodClie', 'refClienteAdd')"
                    no-data-text="Sin resultados" :rules="rules.agregarRules" :error-messages="estadoCliente">
                  </v-autocomplete>
                </div>

                <!-- Campos para le código del producto del cliente -->
                <div class="d-flex">
                  <v-text-field v-model="homologacionProdClie" outlined dense label="Código producto cliente" 
                    :rules="rules.agregarRules" maxlength="40">
                  </v-text-field>
                  <!-- <v-text-field v-model="homologacionProdClie2" outlined dense 
                    label="Código producto cliente sustituto" class="ml-2" maxlength="40">
                  </v-text-field> -->
                </div>

                <!-- Campo par ael nombre del producto del cliente -->
                <div>
                  <v-text-field v-model="homologacionNomProdClie" outlined dense label="Nombre producto cliente" maxlength="150">
                  </v-text-field>
                </div>

                <!-- Campo para buscar y elegir un producto -->
                <div class="d-flex">
                  <v-text-field v-model="filtroCodigo" outlined hide-details dense 
                    label="Código producto Stone" class="mr-2">
                  </v-text-field>
                  <v-text-field v-model="filtroNombre" outlined hide-details dense 
                    label="Nombre producto Stone" class="mr-2">
                  </v-text-field>
                  <v-text-field v-model="filtroDCI" outlined hide-details dense 
                    label="Código DCI">
                  </v-text-field>
                </div>

                <v-data-table dense :loading="loadingInterno" fixed-header :headers="headersInternos" :items="listaDeProductos"
                  :page.sync="paginaInterna" :items-per-page.sync="itemsPorPaginaInterna" :server-items-length="totalPaginasInternas"
                  v-model="productoSeleccionado" class="mt-2 estiloFilas" height="27vh" :footer-props="footerPropsTabla"
                  :single-select="singleSelect" show-select>
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.id">
                        <td>
                          <v-checkbox v-model="productoSeleccionado" :value="item"
                            @change="handleCheckboxChange(item)">
                          </v-checkbox>
                        </td>
                        <td v-for="header in headersInternos" :key="header.field">{{ item[header.field] }}</td>
                      </tr>
                      <tr v-if="calcularNoHayEscrituraInterna() && !noHayResultadoInterno">
                        <td :colspan="headersInternos.length + 1" class="mensaje-interno">
                          <span><v-icon class="icono"> feedback </v-icon></span>
                          <div>
                            <em>Utilice los filtros para buscar un producto.</em>
                          </div>
                        </td>
                      </tr>
                      <tr v-else-if="noHayResultadoInterno">
                        <td :colspan="headersInternos.length + 1" class="mensaje-interno">
                          <span><v-icon color="#ff5252" class="icono">search_off</v-icon></span>
                          <div>
                            <em style="color: #ff5252;">
                              No se encontraron resultados para la búsqueda, ingrese un nuevo valor válido.
                            </em>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                  <template v-slot:footer.page-text="items">
                    {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
                  </template>
                </v-data-table>
                
                <!-- Mensaje de error si la homologación entre el cliente y el producto existe -->
                <div v-if="datosExistentesError !== ''" class="mensaje-error">
                  <span class="error-texto">{{ datosExistentesError }}</span>
                </div>

                <!-- Botones de cerrar y guardar -->
                <div class="d-flex justify-end">
                  <v-btn color="error" text @click="close()">CERRAR</v-btn>
                  <v-btn depressed :disabled="botonDisabled || estadoCliente !== '' || this.homologacionProdClie === null || this.homologacionProdClie === ''" color="success" text
                    @click="guardarRelacion()">
                    GUARDAR
                  </v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-tab-item>

          <!-- Masiva -->
          <v-tab-item>
            <v-card-text class="pa-4">
              <v-form ref="archivoSubido">

                <v-container class="mb-2" v-show="verCSV">

                  <v-row>
                    <v-col cols="12" sm="12" md="12" class="pa-0">
                      <span class="text-h6 text-center pa-2 pt-0">Estructura del archivo</span>
                      <v-card class="pa-2" outlined>
                        <v-card-text class="pa-2">
                          <v-row>
                            <v-col cols="12" sm="12" md="12" class="pa-2">
                              <span> Código de cliente; Código de producto de Stone; Código del producto del cliente; Nombre del producto del cliente </span>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                </v-container>

                <div class="archivo" v-show="verCSV">
                  <div>
                    <input :disabled="leyendoArchivo" type="file" class="seleccionarArchivo" accept=".csv"
                    @change="lectorCSV($event)" ref="cargaDeRelaciones">
                  </div>
                  <p v-if="validandoDocumento" style="padding-top: 0.6rem;">
                    Validando la información del archivo, espere un momento...
                    <v-icon :class="{ 'rotate-animation': validandoDocumento }"
                      large>rotate_right
                    </v-icon>
                  </p>
                  <p v-else-if="formatoIncorrecto" style="color: red; margin-top: 1.4rem;">
                    <v-icon class="mr-2" style="color: red;">error</v-icon>
                    Formato incorrecto, debe subir o arrastrar un archivo .csv.
                  </p>
                  <p v-else>Arrastre un archivo .CSV con la homologación de los productos,
                    <br>
                    También puede hacer click en este cuadro.
                  </p>
                </div>
                <v-alert v-if="tabla" class="pa-2 ma-0" color="primary" transition="scale-transition" outlined>
                  <h4 class="d-flex justify-center"><v-icon color="primary" class="mr-2">info</v-icon>{{ mensajeCantidad }}</h4>
                </v-alert>
                <v-simple-table class="tablaRelaciones ma-1" fixed-header height="330px" v-show="tabla"
                  ref="tablaRelacionesMasivas" id="miTabla">
                  <template v-slot:default>
                    <thead class="header">
                      <tr>
                        <th> CÓDIGO CLIENTE </th>
                        <th> PRODUCTO STONE </th>
                        <th> PRODUCTO CLIENTE </th>
                        <th> NOMBRE PRODUCTO CLIENTE </th>
                        <th> VALIDACIÓN </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(registro, registroIndex) in registrosFiltrados" :key="registroIndex">
                        <td>{{ registro.codClie }}</td>
                        <td>{{ registro.codProd }}</td>
                        <td>{{ registro.prodClie }}</td>
                        <td>{{ registro.nomPrCli }}</td>
                        <td>
                          <div
                            v-if="validacionesFiltradas[registroIndex] && validacionesFiltradas[registroIndex].length > 0">
                            <div v-for="(mensaje, subIndex) in validacionesFiltradas[registroIndex]"
                              :key="subIndex" class="error-message">
                              {{ mensaje }}
                            </div>
                          </div>
                          <div v-else>
                            <v-icon color="green">check_circle</v-icon>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <!-- Botones de cerrar y guardar -->
                <div class="d-flex justify-end">
                  <v-btn color="error" :disabled="leyendoArchivo" text @click="close()">CERRAR</v-btn>
                  <v-btn v-if="mostrarBotones" color="blue" text @click="volverACargarDocumento">VOLVER</v-btn>
                  <v-btn v-if="mostrarBotones && hayErrores && cantidadRegistros > 0" 
                    color="success" text @click="descargarResultadoDeValidaciones()">
                    DESCARGAR
                  </v-btn>
                </div>
                <div v-if="inProgress === true" class="overlay">
                  <v-alert class="notificationValidation" persistent transition="dialog-bottom-transition">
                    <v-icon class="d-flex justify-center rotate-animation" color="white" large>rotate_right</v-icon>
                    <span class="d-flex justify-center">Guardando</span>
                  </v-alert>
                </div>
              </v-form>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
    
    <!-- dialogo para editar un registro -->
    <v-dialog v-model="editarHomologacion" persistent transition="dialog-bottom-transition" max-width="39rem">
      <v-card>
        <v-card-title class="fondoDialog">
          <span class="text-h6">Editar</span>
        </v-card-title>

        <v-card-text class="pa-5 pb-3 pt-3">
          <v-form ref="refEditar">
            <div class="d-flex">
              <v-text-field v-model="editar.codProdClie" outlined dense label="Código producto cliente" 
                :rules="rules.agregarRules" maxlength="40">
              </v-text-field>
              <!-- <v-text-field v-model="editar.codProdSust" outlined dense 
                label="Código producto cliente sustituto" class="ml-2" maxlength="40">
              </v-text-field> -->
            </div>
            <div>
              <v-text-field v-model="editar.nomProdClie" outlined dense label="Nombre producto cliente" maxlength="150">
              </v-text-field>
            </div>

            <div class="d-flex justify-end">
              <v-btn color="error" text @click="cerrarDialogoEditar()">CERRAR</v-btn>
              <v-btn depressed :disabled="inProgress || editar.codProdClie === null || this.editar.codProdClie === ''" 
                color="success" text @click="actualizar()">
                ACTUALIZAR
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Tabla que muestra los registros de homologación de productos -->
    <v-data-table :loading="loading" fixed-header :headers="headersRegistros" :items="listarRegistros"
      :page.sync="pagina" :items-per-page.sync="itemsPorPagina" :server-items-length="totalPaginas"
      class="elevation mt-4" height="66vh" :footer-props="footerPropsTabla">
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(item) in items" :key="item.index">
            <td>{{ item.codClie }}</td>
            <td>
              <v-tooltip v-if="item.nomDci || item.innovador" bottom color="blue-grey darken-4">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ item.codDci }}</span>
                </template>
                <div>
                  <span v-if="item.nomDci">Nombre DCI: {{ item.nomDci }}<br/></span>
                  <span v-if="item.innovador">Innovador: {{ item.innovador }}</span>
                </div>
              </v-tooltip>
              <span v-else>{{ item.codDci }}</span>
            </td>
            <td>{{ item.codProd }} - {{ item.nomProd }}</td>
            <td>{{ item.dprClie ? item.prodClie + ' - ' + item.dprClie : item.prodClie }}</td>
            <td>
              <v-icon :color="item.actEsta === 'A' ? 'success' : 'error'"> 
                {{ item.actEsta === 'A' ? 'check_circle' : 'cancel' }}
              </v-icon>
              {{ item.actEsta === 'A' ? 'Activo' : 'Inactivo' }}
            </td>
            <td class="text-center">
              <v-tooltip bottom color="orange">
                <template v-slot:activator="{ on, attrs }">
                  <button v-bind="attrs" v-on="on" @mousedown.prevent="editarRegistro(item)">
                    <v-icon color="orange"> border_color </v-icon>
                  </button>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip bottom :color="item.actEsta === 'A' ? 'error' : 'success'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ms-2" v-bind="attrs" v-on="on" icon @mousedown.prevent="abrirDialogoEstado(item)">
                    <v-icon :color="item.actEsta === 'A' ? 'error' : 'success'">
                      {{ item.actEsta === 'A' ? 'person_add_disabled' : 'how_to_reg' }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ item.actEsta === 'A' ? 'Inactivar' : 'Activar' }}</span>
              </v-tooltip>
            </td>
          </tr>
          <tr v-if="calcularNoHayEscritura() && !noHayResultadosBusqueda">
            <td :colspan="headersRegistros.length + 1" class="mensaje">
              <span><v-icon class="icono"> feedback </v-icon></span>
              <div>
                <em>No hay registros, utilice los filtros para buscar un producto homologado.
                </em>
              </div>
            </td>
          </tr>
          <tr v-else-if="noHayResultadosBusqueda">
            <td :colspan="headersRegistros.length + 1" class="mensaje">
              <span><v-icon color="#ff5252" class="icono">search_off</v-icon></span>
              <div>
                <em style="color: #ff5252;">No se encontraron resultados para la búsqueda, ingrese un nuevo valor.</em>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:footer.page-text="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
    </v-data-table>

    <!-- dialogo para cambiar el estado -->
    <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="22.7rem" persistent>
      <v-card>
        <v-card-title class="fondoDialog">
          <span class="text-h6">{{ mensajeEstado }}</span>
        </v-card-title>
        <v-card-text>
          <div class="d-flex justify-end" style="padding-top: 1.3rem">
            <v-btn color="error" text @click="cerrarDialogoEstado()">No</v-btn>
            <v-btn color="success" depressed text :disabled="inProgress" @click="cambiarEstado()">Si</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Mensaje de alerta para cuando la creacion por plano no genero errores de validación -->
    <template>
      <div v-if="procesoDeCreacion" class="overlay">
        <v-alert class="notificationValidationEstado ancho-creacion" persistent transition="dialog-bottom-transition">
          <v-icon class="d-flex justify-center" color="white" large>task_alt</v-icon>
          <span class="d-flex justify-center">{{ mensajeCreacion }}</span>
        </v-alert>
      </div>
    </template>

    <!-- Contenedor para mostrar los mensajes de error -->
    <div v-if="mostrarNotificacion" class="notificacion advertencia">
      <span><v-icon color="#f80505" class="rotate-animation-notification size">highlight_off</v-icon></span>
      <span>{{ mensajeNotificacion }} <br> {{ pathNotificacion }}</span>
    </div>

  </v-main>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";
import Papa from 'papaparse';

const debounce = function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

export default {
  name: "Homologacion",

  data() {
    return {
      buscarCliente: null,
      buscarCodigoDci: null,
      buscarProductoStone: null,
      buscarProductoCliente: null,
      clientes: [],
      dialog: false,
      homologacionCodClie: null,
      homologacionProdClie: null,
      homologacionProdClie2: null,
      homologacionNomProdClie: null,
      filtroNombre: null,
      filtroCodigo: null,
      filtroDCI: null,
      loadingInterno: false,
      headersInternos: [
        { text: "CÓDIGO", field: 'codProd', width: "12%", align: 'left', sortable: false },
        { text: "NOMBRE", field: 'nomProd', width: "60%", align: 'left', sortable: false },
        { text: "CÓDIGO DCI", field: 'codDci', width: "28%", align: 'left', sortable: false },
      ],
      listaDeProductos: [],
      paginaInterna: 1,
      totalPaginasInternas: 0,
      itemsPorPaginaInterna: 10,
      singleSelect: true,
      productoSeleccionado: [],
      seleccionAnterior: [],
      noHayResultadoInterno: false,
      datosExistentesError: '',
      botonDisabled: true,
      menuPropsCliente: { offsetY: true, maxHeight: 170, maxWidth: null },
      estadoCliente: '',
      dialogoCambiarEstado: false,
      mensajeEstado: '',
      registroSeleccionado: {},
      inProgress: false,
      leyendoArchivo: false,
      filasSeleccionadas: [],
      validandoDocumento: false,
      tabla: false,
      verCSV: true,
      registros: [],
      validaciones: [],
      formatoIncorrecto: false,
      archivoSubido: false,
      mostrarBotones: false,
      noHayResultadosBusqueda: false,
      opciones: 0,
      usuario: '',
      listarRegistros: [],
      pagina: 1,
      totalPaginas: 0,
      itemsPorPagina: 10,
      loading: false,
      headersRegistros: [
        { text: "CÓDIGO CLIENTE", width: "11%", sortable: false, },
        { text: "CÓDIGO DCI", width: "13%", sortable: false, },
        { text: "PRODUCTO STONE", width: "27%", sortable: false, },
        { text: "PRODUCTO CLIENTE", width: "27%", sortable: false, },
        { text: "ESTADO", align: "center", width: "10%", sortable: false, },
        { text: "ACCIONES", align: "center", width: "10%", sortable: false, }
      ],
      footerPropsTabla: {
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Items por página:',
        'show-current-page': true,
        'show-first-last-page': true,
      },
      mostrarNotificacion: false,
      mensajeNotificacion: "",
      pathNotificacion: "",
      procesoDeCreacion: false,
      resettingForm: false,
      rules: {
        agregarRules: [
          v => !!v || "Este campo es requerido.",
        ],
      },
      editarHomologacion: false,
      editar: {
        codProdClie: null,
        nomProdClie: null,
        codProdSust: null,
      },
      cantidadRegistros: null,
      codigoClienteAnterior: '',
    }
  },

  mounted() {
    this.usuario = this.auth.username.toUpperCase();
  },

  created() {
    this.listClientes();
  },

  computed: {
    ...mapState(["auth", "enterprise"]),
    /** Retorna un mensaje con la cantidad de relaciones creadas por plano */
    mensajeCreacion() {
      const cantidad = this.cantidadRegistros > 1 ? 'registros' : 'registro';
      const mensaje = `Creación exitosa para ${this.cantidadRegistros} ${cantidad}.`;
      return mensaje;
    },
    /**
     * Devuelve la cantidad de registros que tienen mensajes de validación.
     * @returns {number} - La cantidad de registros con mensajes de validación.
     */
    registrosConError() {
      const registrosConError = this.validaciones.filter(validacion => validacion.length > 0);
      return registrosConError.length;
    },
    /**
     * Filtra y devuelve los registros que tienen mensajes de validación.
     * Si no hay registros con mensajes de validación, devuelve todos los registros.
     * @returns {Array} - Los registros que tienen mensajes de validación o todos los registros si no hay errores.
     */
    registrosFiltrados() {
      if (this.registrosConError > 0) {
        const registrosFiltrados = this.registros.filter((registro, index) => this.validaciones[index].length > 0);
        return registrosFiltrados;
      }
      return this.registros;
    },
    /**
     * Filtra y devuelve las validaciones que tienen mensajes de error.
     * Si no hay registros con mensajes de validación, devuelve todas las validaciones.
     * @returns {Array} - Las validaciones que tienen mensajes de error o todas las validaciones si no hay errores.
     */
    validacionesFiltradas() {
      if (this.registrosConError > 0) {
        return this.validaciones.filter((validacion, index) => this.validaciones[index].length > 0);
      }
      return this.validaciones;
    },
    /**
     * Verifica si existen errores en las validaciones.
     * @returns {boolean} - true si hay al menos un registro con mensajes de validación, false en caso contrario.
     */
    hayErrores() {
      return this.validaciones.some(validacion => validacion.length > 0);
    },
    /** Cuenta los registros con errores */
    cantidadRegistrosConError() {
      return this.registros.filter((registro, index) => this.validaciones[index].length > 0).length;
    },
    /** Establece un valor en un mensaje para detallar la cantidad de registros procesados y si tienen error */
    mensajeCantidad() {
      const validoText = this.cantidadRegistros === 1 ? 'Se validó' : 'Se validaron';
      const registrosText = this.cantidadRegistros === 1 ? 'registro' : 'registros';
      let mensaje = `${validoText} ${this.cantidadRegistros} ${registrosText}`;
      if (this.cantidadRegistrosConError > 0) {
        const erroresText = this.cantidadRegistrosConError === 1 ? 'presenta el siguiente error' : 'presentan los siguientes errores';
        mensaje += ` y ${this.cantidadRegistrosConError} ${erroresText}.`;
      } else {
        mensaje += '.';
      }
      return mensaje;
    },
  },

  watch: {
    pagina: function () {
      this.listarRelaciones();
    },
    itemsPorPagina: function () {
      this.pagina = 1;
      this.listarRelaciones();
    },
    'buscarCliente': debounce(function () {
      this.pagina = 1;
      this.listarRelaciones();
    }, 700),
    'buscarCodigoDci': debounce(function () {
      this.pagina = 1;
      this.listarRelaciones();
    }, 500),
    'buscarProductoStone': debounce(function () {
      this.pagina = 1;
      this.listarRelaciones();
    }, 500),
    'buscarProductoCliente': debounce(function () {
      this.pagina = 1;
      this.listarRelaciones();
    }, 500),
    'dialog': function(newValue) {
      if (newValue === true) {
        setTimeout(() => {
          this.$nextTick(() => {
            if (this.$refs.refClienteAdd) {
              this.menuPropsCliente.maxWidth = this.$refs.refClienteAdd.$el.offsetWidth;
            }
          })
        }, 100)
      }
    },
    paginaInterna: function () {
      this.listarProductos();
    },
    itemsPorPaginaInterna: function () {
      this.paginaInterna = 1;
      this.listarProductos();
    },
    'filtroNombre': debounce(function () {
      this.productoSeleccionado = [];
      this.paginaInterna = 1;
      this.listarProductos();
    }, 1000),
    'filtroCodigo': debounce(function () {
      this.productoSeleccionado = [];
      this.paginaInterna = 1;
      this.listarProductos();
    }, 500),
    'filtroDCI': debounce(function () {
      this.productoSeleccionado = [];
      this.paginaInterna = 1;
      this.listarProductos();
    }, 500),
    'homologacionCodClie': debounce(function () {
      this.validarExistencia();
    }, 100),
    'productoSeleccionado': debounce(function () {
      this.validarExistencia();
    }, 100),
    'homologacionProdClie': debounce(function () {
      this.validarExistencia();
    }, 100),
    'procesoDeCreacion': function(newValue) {
      if (newValue === true) {
        setTimeout(() => {
          this.procesoDeCreacion = false;
          this.listarRelaciones();
          this.cantidadRegistros = null;
        }, 3500);
      }
    },
  },

  methods: {
    /**
     * Consulta las clientes y las establece en el array clientes,
     * También define el tamaño del campo por medio de las propiedades del refs.
     */
    listClientes() {
      this.clientes = [];
      this.$http.get(`msa-administration/api/hd-suge/list-clientes`, {
        params: {
          codEmpr: this.enterprise.code,
        },
      })
      .then((response) => {
        this.clientes = response.data.filter(item => item.codClie > 0).map(item => ({
          text: item.nomClie && item.nomClie !== '' ? `${item.codClie} - ${item.nomClie.trim()}` : `${item.codClie}`,
          value: item.rmtSuge,
          codClie: item.codClie,
          estado: item.actEsta,
          estClie: item.estClie,
        }))
      }).catch((error) => {
        this.verError(error);
      })
    },
    /**
     * Permite la busqueda de un producto para relacionarlo a un cliente y homologarlo.
     * Establece los resultados de la busqueda en el array listaDeProductos
     * y los muestra de manera paginada.
     */
    listarProductos() {
      const afiliadoSelecionado = this.seleccionAnterior.slice();
      this.loadingInterno = true;
      let nombre = this.filtroNombre === null ? '' : this.filtroNombre.toLowerCase();
      let numero = this.filtroCodigo === null ? '' : this.filtroCodigo.toLowerCase();
      let dci = this.filtroDCI === null ? '' : this.filtroDCI.toLowerCase();
      if (nombre === '' && numero === '' && dci === '') {
        this.listaDeProductos = [];
        this.loadingInterno = false;
        this.totalPaginasInternas = 0;
        this.noHayResultadoInterno = false;
        return;
      }
      this.$http.get(`msa-administration/api/hd-suge/page-productos`, {
        params: {
          page: this.paginaInterna - 1,
          size: this.itemsPorPaginaInterna,
          codEmpr: this.enterprise.code,
          codProd: numero,
          nomProd: nombre,
          codDci: dci,
        },
      }).then(response => {
        this.listaDeProductos = response.data.content.map(producto => ({
          ...producto,
        }));
        this.totalPaginasInternas = response.data.totalElements;
        if (response.data.totalElements === 0) {
          this.noHayResultadoInterno = true;
        } else {
          this.noHayResultadoInterno = false;
        }
        this.productoSeleccionado = afiliadoSelecionado.filter(producto => {
          return this.listaDeProductos.some(item => item.rmtRela === producto.rmtRela);
        })
        this.loadingInterno = false;
      }).catch(error => {
        this.verError(error);
        this.loadingInterno = false;
      })
    },
    /**
     * Obtiene la lista de relaciones mediante una solicitud HTTP GET al servidor.
     * Las relaciones se obtienen con paginación y se filtran según los parámetros especificados.
     * Se actualiza el número total de páginas.
     * Además, se controla el estado de carga durante la solicitud.
     */
    listarRelaciones() {
      this.loading = true;
      let cliente = this.buscarCliente === null ? '' : this.buscarCliente.trim();
      let dci = this.buscarCodigoDci === null ? '' : this.buscarCodigoDci.trim();
      let codProd = this.buscarProductoStone === null ? '' : this.buscarProductoStone.trim();
      let prodClie = this.buscarProductoCliente === null ? '' : this.buscarProductoCliente.trim();
      if (cliente === '' && dci === '' && codProd === '' && prodClie === '') {
        this.listarRegistros = [];
        this.loading = false;
        this.totalPaginas = 0;
        this.noHayResultadosBusqueda = false;
        return;
      }
      this.$http.get(`msa-administration/api/hdRCupCCliente/page`, {
        params: {
          page: this.pagina - 1,
          size: this.itemsPorPagina,
          idEmpresa: this.enterprise.code,
          codClie: `${cliente}`,
          codDci: `${dci}`,
          codProd: `${codProd}`,
          prodClie: `${prodClie}`,
        },
      }).then(response => {
        this.listarRegistros = response.data.content;
        // Actualizar el número total de páginas
        this.totalPaginas = response.data.totalElements;
        if (response.data.totalElements === 0) {
          this.noHayResultadosBusqueda = true;
        } else {
          this.noHayResultadosBusqueda = false;
        }
        this.loading = false;
      }).catch(error => {
        this.verError(error);
        this.loading = false;
      })
    },
    /**
     * Maneja el cambio de estado del checkbox.
     * Actualiza los registros seleccionados y realiza acciones en función del estado del checkbox.
     * @param item - Registro seleccionado.
     */
    handleCheckboxChange(item) {
      if (this.resettingForm) return;
      
      if (item) {
        this.productoSeleccionado = [item];
      } else {
        this.productoSeleccionado = [];
        this.valorSeleccionado = null;
      }
      this.seleccionAnterior = this.productoSeleccionado.slice();
    },
    /**
      * Calcula si no hay escritura en los campos de búsqueda.
      * @returns {boolean} - Valor que indica si no hay escritura en los campos de búsqueda.
      */
    calcularNoHayEscrituraInterna() {
      const nombre = (this.filtroNombre === null || this.filtroNombre === '') ? true : false;
      const codigo = (this.filtroCodigo == null || this.filtroCodigo === '') ? true : false;
      const dci = (this.filtroDCI === null || this.filtroDCI === '') ? true : false;
      return nombre && codigo && dci;
    },
    /**
     * Se encarga de realizar una validación para verificar si existe un registro entre el rmtRela asociado al producto
     * y rmtSuge asociado al código del cliente seleccionados.
     */
    validarExistencia() {
      const actInac = this.productoSeleccionado.length === 1 ? this.productoSeleccionado[0].actInac : null;
      const rmtSuge = (this.homologacionCodClie === null || this.homologacionCodClie === '') ? null : this.homologacionCodClie;
      const prodClie = this.homologacionProdClie ? this.homologacionProdClie.trim() : null;

      let codClie = null;

      if (rmtSuge !== null) {
        const cliente = this.clientes.filter(item => item.value === rmtSuge);
        this.estadoCliente = cliente.length > 0 && cliente[0].estado === 'B' || cliente[0].estClie === 'B' ? 'El cliente está inactivo.' : '';
        codClie = cliente.length > 0 ? cliente[0].codClie : null;
      } else {
        this.estadoCliente = '';
      }

      if (actInac !== null && actInac === 'I') {
        this.datosExistentesError = "El producto esta inactivo.";
        this.botonDisabled = true;
        return;
      }
      
      // const rmtRela = this.productoSeleccionado.length === 1 ? this.productoSeleccionado[0].rmtRela : null;
      const codProd = this.productoSeleccionado.length === 1 ? this.productoSeleccionado[0].codProd : null;

      if (codClie !== null && codProd !== null && prodClie !== null) {
        
        this.$http.get(`msa-administration/api/hdRCupCCliente/existe/${codClie}/${codProd}/${prodClie}`)
        .then((response) => {
          if (response.data !== "") {
            this.datosExistentesError = response.data;
            this.botonDisabled = true;
          } else {
            this.datosExistentesError = "";
            this.botonDisabled = false; 
          }
        }).catch((error) => {
          this.verError(error);
          this.datosExistentesError = "Error en la validación de datos";
          this.botonDisabled = true;
        });
      } else {
        this.datosExistentesError = "";
        this.botonDisabled = true;
      }
    },
    /**
     * Maneja el evento de presionar la tecla Tab para campos v-autocomplete.
     * Si se presiona la tecla Tab y el valor actual del modelo es null,
     * desenfoca el campo y establece el valor del modelo como una cadena vacía.
     *
     * @param {KeyboardEvent} event - El objeto del evento de teclado.
     * @param {string} model - El nombre de la propiedad del modelo.
     * @param {string} ref - El nombre de la referencia del campo v-autocomplete.
     */
    tabulador(event, model, ref) {
      if (event.key === 'Tab' && this[model] === null) {
        this.$refs[ref].blur();
        this[model] = '';
      }
    },
    /**
     * Calcula si no hay escritura en los campos de búsqueda
     * @returns {boolean} - Indicador de no hay escritura
     */
    calcularNoHayEscritura() {
      const cliente = (this.buscarCliente === null || this.buscarCliente === '') ? true : false;
      const dci = (this.buscarCodigoDci === null || this.buscarCodigoDci === '') ? true : false;
      const codProd = (this.buscarProductoStone === null || this.buscarProductoStone === '') ? true : false;
      const prodClie = (this.buscarProductoCliente === null || this.buscarProductoCliente === '') ? true : false;
      return cliente && dci && codProd && prodClie;
    },
    /**
     * Guarda la información de un nuevo registro en el servidor.
     * Crea un objeto con los datos del registro, incluyendo la fecha de creación actual,
     * y realiza una solicitud al servidor para ingresar el registro. Luego, actualiza la lista
     * de las relaciones y restablece el formulario.
     */
    guardarRelacion() {
      this.botonDisabled = true;
      const horaActual = new Date;
      // Realiza la petición al backend para guardar el registro
      const homologacion = {
        rmtRela: this.productoSeleccionado[0].rmtRela,
        rmtSuge: this.homologacionCodClie,
        prodClie: this.homologacionProdClie.trim(),
        // proClie2: this.homologacionProdClie2 === null ? '' : this.homologacionProdClie2.trim(),
        proClie2: '',
        nomPrCli: this.homologacionNomProdClie ? this.homologacionNomProdClie.trim() : '',
        codPrio: this.productoSeleccionado[0].codPrio,
        actEsta: 'A',
        codEmpr: this.enterprise.code,
        fecha: horaActual.toISOString(),
        usuario: this.usuario,
        lastProd: '',
      };
      // Realizar la petición POST al controlador para guardar el registro
      this.$http
        .post(`msa-administration/api/hdRCupCCliente/save`, homologacion)
        .then(() => {
          this.listarRelaciones();
          this.close();
        })
        .catch(error => {
          this.botonDisabled = false;
          this.verError(error);
        });
    },
    /**
     * Abre el diálogo para cambiar el estado de un registro.
     * Establece la propiedad 'dialogoCambiarEstado' en true para mostrar el diálogo.
     * Guarda el registro seleccionado en la propiedad 'registroSeleccionado'.
     * Este método se invoca cuando se desea cambiar el estado de un registro.
     * @param {object} item - El registro seleccionado.
     */
    abrirDialogoEstado(item) {
      this.dialogoCambiarEstado = true;
      this.registroSeleccionado = item;
      this.mensajeEstado = this.registroSeleccionado.actEsta === 'A' ? '¿Desea inactivar el registro?' : '¿Desea activar el registro?';
      setTimeout(() => {
        this.mensajeEstado = this.registroSeleccionado.actEsta === 'A' ? '¿Desea inactivar el registro?' : '¿Desea activar el registro?';
      }, 4000);
    },
    /**
     * Cambia el estado de un registro.
     * Actualiza el estado del registro seleccionado ('registroSeleccionado') según la lógica definida.
     * Realiza una solicitud HTTP PUT al servidor para actualizar el estado de un registro.
     * Después de la actualización exitosa, se actualiza la lista de registro.
     */
    cambiarEstado() {
      this.inProgress = true;
      this.registroSeleccionado.actEsta = this.registroSeleccionado.actEsta === 'A' ? 'B' : 'A';
      const horaActual = new Date;
      const cambiarEstado = {
        rmtRela: this.registroSeleccionado.rmtRela,
        rmtSuge: this.registroSeleccionado.rmtSuge,
        prodClie: this.registroSeleccionado.prodClie ? this.registroSeleccionado.prodClie.trim() : '',
        proClie2: this.registroSeleccionado.proClie2 ? this.registroSeleccionado.proClie2.trim() : '',
        nomPrCli: this.registroSeleccionado.dprClie ? this.registroSeleccionado.dprClie.trim() : '',
        codPrio: this.registroSeleccionado.codPrio,
        actEsta: this.registroSeleccionado.actEsta,
        codEmpr: this.enterprise.code,
        fecha: horaActual.toISOString(),
        usuario: this.usuario,
        lastProd: '',
      };
      this.$http
        .put(`msa-administration/api/hdRCupCCliente/update`, cambiarEstado)
        .then(() => {
          this.listarRegistros = [];
          this.totalPaginas = 0;
          this.cerrarDialogoEstado();
          this.listarRelaciones();
        }).catch(error => {
          this.inProgress = false;
          this.verError(error);
        })
    },
    /**
     * Restablece los valores usados para el cambio de estado y cierra el dialogo.
     */
    cerrarDialogoEstado() {
      this.registroSeleccionado = {};
      this.dialogoCambiarEstado = false;
      setTimeout(() => {
        this.inProgress = false;
      }, 300)
    },
   /**
     * Abre el diálogo para editar un registro.
     * Establece la propiedad 'editarHomologacion' en true para mostrar el diálogo.
     * Guarda el registro seleccionado en la propiedad 'registroSeleccionado'.
     * @param {object} item - El registro seleccionado.
     */
    editarRegistro(item) {
      this.registroSeleccionado = item;
      this.editar.codProdClie = item.prodClie ? item.prodClie : null;
      this.editar.nomProdClie = item.dprClie ? item.dprClie : null;
      this.editar.codProdSust = item.proClie2 && item.proClie2 !== '0' ? item.proClie2 : null;
      this.editarHomologacion = true;
      this.codigoClienteAnterior = item.prodClie ? item.prodClie : '';
    },
    /**
     * Actualiza los valores del cliente en un registro seleccionado ('registroSeleccionado') según la lógica definida.
     * Realiza una solicitud HTTP PUT al servidor para actualizar el registro.
     * Después de la actualización exitosa, se actualiza la lista de registro.
     */
    actualizar() {
      this.inProgress = true;
      this.registroSeleccionado.actEsta = 'A';
      const horaActual = new Date;
      const editar = {
        rmtRela: this.registroSeleccionado.rmtRela,
        rmtSuge: this.registroSeleccionado.rmtSuge,
        prodClie: this.editar.codProdClie ? this.editar.codProdClie.trim() : '',
        proClie2: this.editar.codProdSust ? this.editar.codProdSust.trim() : '',
        nomPrCli: this.editar.nomProdClie ? this.editar.nomProdClie.trim() : '',
        codPrio: this.registroSeleccionado.codPrio,
        actEsta: this.registroSeleccionado.actEsta,
        codEmpr: this.enterprise.code,
        fecha: horaActual.toISOString(),
        usuario: this.usuario,
        lastProd: this.codigoClienteAnterior,
      };

      this.$http
        .put(`msa-administration/api/hdRCupCCliente/update`, editar)
        .then(() => {
          this.listarRegistros = [];
          this.totalPaginas = 0;
          this.cerrarDialogoEditar();
          this.listarRelaciones();
        }).catch(error => {
          this.inProgress = false;
          this.verError(error);
        })
    },
    /**
     * Restablece los valores usados para la edición y cierra el dialogo.
     */
    cerrarDialogoEditar() {
      this.registroSeleccionado = {};
      this.editarHomologacion = false;
      this.editar.codProdClie = null;
      this.editar.codProdSust = null;
      this.editar.nomProdClie = null;
      this.codigoClienteAnterior = '';
      setTimeout(() => {
        this.inProgress = false;
      }, 300)
      this.$refs.refEditar?.reset();
    },
    /**
     * Método para leer la información de un archivo CSV, realizar validaciones en el backend
     * y mostrar los resultados en una tabla.
     * Este método se encarga de leer el contenido de un archivo CSV seleccionado mediante un
     * input de tipo archivo. Luego, procesa los datos y realiza una serie de validaciones en el
     * backend usando Promises. Finalmente, muestra los registros válidos en una tabla en la interfaz.
     * @param {Event} event - El evento del input de tipo archivo que contiene el archivo CSV.
     */
    lectorCSV(event) {
      this.leyendoArchivo = true;
      this.formatoIncorrecto = false;
      this.mensajeIncorrecto = '';
      const file = event.target.files[0];
      
      if (!file || file.name.split('.').pop() !== 'csv') {
        this.formatoIncorrecto = true;
        this.mensajeIncorrecto = 'Formato incorrecto, debe subir o arrastrar un archivo .csv.';
        this.leyendoArchivo = false;
        return;
      }
      
      this.validandoDocumento = true;
      this.registros = [];
      this.registrosTemporales = [];

      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        const parsedData = Papa.parse(content, {
          header: false,
          delimiter: ';',
        });
        if (!parsedData.data || parsedData.data.length === 0 || (parsedData.data.length === 1 && parsedData.data[0].length === 1 && parsedData.data[0][0] === '')) {
          this.formatoIncorrecto = true;
          this.mensajeIncorrecto = 'El archivo está vacío. Por favor, suba un archivo con contenido.';
          this.validandoDocumento = false;
          this.leyendoArchivo = false;
          return;
        }
        const registros = [];
        const headerRow = parsedData.data[0];
        const isHeaderRow = /^\d+$/.test(headerRow[0]) && /^\d+$/.test(headerRow[1]); 
        for (let index = isHeaderRow ? 0 : 1; index < parsedData.data.length - 1; index++) {
          const row = parsedData.data[index];
          if ((row[0] !== undefined && row[0] !== '') || (row[1] !== undefined && row[1] !== '') || (row[2] !== undefined && row[2] !== '')) {
            registros.push({
              codClie: row[0],
              codProd: row[1],
              prodClie: row[2],
              // sustituto: row[3],
              nomPrCli: row[3],
            });
          }
        }
        if (registros.length === 0) {
          this.formatoIncorrecto = true;
          this.mensajeIncorrecto = 'El archivo está vacío. Por favor, suba un archivo con contenido.';
          this.validandoDocumento = false;
          this.leyendoArchivo = false;
          return;
        }
        this.cantidadRegistros = registros.length;
        this.registros = registros;
        this.registrosTemporales.push([...registros]);
        this.archivoSubido = true;
        this.validaciones = Array.from({ length: this.registros.length }, () => []);
        this.registros = [];
        const backendCalls = [
          this.procesarArchivo(registros),
        ];
        Promise.all(backendCalls)
          .then(() => {
            this.$nextTick(() => {
              this.verificarValidaciones();
            });
          })
          .catch((error) => {
            this.verError(error);
            this.validandoDocumento = false;
            this.mostrarBotones = false;
            this.leyendoArchivo = false;
          });
      }; 
      reader.onerror = () => {
        this.formatoIncorrecto = true;
        this.mensajeIncorrecto = 'Error al leer el archivo. Verifique el contenido e inténtelo de nuevo.';
        this.validandoDocumento = false;
        this.leyendoArchivo = false;
      };
      reader.readAsText(file);
    },
    /**
     * Valida los registros en el archivo tomando el código del cliente, producto del cliente y el código de prooducto de stone, 
     * para comprobar si cumplen las condiciones y poder guardar la homologación de los productos.
     * 
     * @param {Object[]} registros - La lista de registros del archivo para validar en el back-end.
     * @returns {Promise} - Una Promise que se resuelve cuando se completan las validaciones o se rechaza en caso de error.
     */
    procesarArchivo(registros) {
      const registrosValidos = [];

      registros.forEach((registro, index) => {
        let codClieValido = false;
        let codProdValido = false;
        let prodClieValido = false;
        // let nomPrCliValido = false;
        let mensajeError = "";

        const codClie = registro.codClie ? registro.codClie.trim() : "";
        const codProd = registro.codProd ? registro.codProd.trim() : "";

        if (codClie !== "") {
          codClieValido = /^\d+$/.test(codClie);
        }
        if (codProd !== "") {
          codProdValido = /^\d+$/.test(codProd);
        }
        if (registro.prodClie && registro.prodClie.trim() !== "") {
          prodClieValido = true;
        }
        // if (registro.nomPrCli && registro.nomPrCli.trim() !== "") {
        //   nomPrCliValido = true;
        // }

        if (codClieValido && codProdValido && prodClieValido) {
          registrosValidos.push({
            codEmpr: this.enterprise.code, 
            codClie: Number(registro.codClie.trim()), 
            codProd: Number(registro.codProd.trim()), 
            prodClie: registro.prodClie.trim().substring(0, 40), 
            // proClie2: registro.sustituto ? registro.sustituto.trim().substring(0, 40) : '',
            proClie2: '',
            nomPrCli: registro.nomPrCli ? registro.nomPrCli.trim().substring(0, 150) : '',
            usuario: this.usuario 
          });
        } else {
          registrosValidos.push({ 
            codEmpr: this.enterprise.code, 
            codClie: !codProdValido && codClieValido ? Number(registro.codClie.trim()) : null,
            codProd: !codClieValido && codProdValido ? Number(registro.codProd.trim()) : null, 
            prodClie: '', 
            proClie2: '',
            nomPrCli: '',
            usuario: this.usuario 
          });
        }

        if (!registro.codClie) {
          mensajeError = "El código del cliente no puede estar vacío.";
        } else if (!/^\d+$/.test(registro.codClie.trim())) {
          mensajeError += "Código de cliente incorrecto.\n";
        } 
        if (!registro.codProd) {
          mensajeError += "El Código de Stone no puede estar vacío.\n";
        } else if (!/^\d+$/.test(registro.codProd.trim())) {
          mensajeError += "Código Stone incorrecto.\n";
        } 
        if (!registro.prodClie) {
          mensajeError += "El producto del cliente no puede estar vacío.\n";
        } 
        // if (!registro.nomPrCli) {
        //   mensajeError += "El nombre del producto del cliente no puede estar vacío.\n";
        // }

        if (mensajeError) {
          this.validaciones[index].push(mensajeError);
        }
      });
      
      return this.$http.post("msa-administration/api/hdRCupCCliente/save-list", registrosValidos)
      .then((response) => {
        this.filasSeleccionadas = [];
        if (Array.isArray(response.data) && response.data.length > 0) {
          response.data.forEach((mensaje, index) => {
            if (mensaje.trim() !== '') {
              if (!Array.isArray(this.validaciones[index])) {
                this.validaciones[index] = [];
              }
              this.validaciones[index].push(mensaje);
            }
          });
        }
        this.verCSV = true;
        this.validandoDocumento = true;
      }).catch((error) => {
        this.verError(error);
        this.validandoDocumento = true;
        return Promise.reject(error);
      });
    },
    /**
     * Verifica las validaciones de los registros procesados.
     * Si hay errores de validación, muestra la tabla de errores.
     * Si no hay errores, cierra la vista y muesatra un mensaje exitoso con la cantidad de relaciones procesadas.
     */
    verificarValidaciones() {
      if (this.validaciones.some(val => val.length > 0)) {
        this.registros = this.registrosTemporales.flat();
        this.registrosTemporales = [];
        this.verCSV = false;
        this.tabla = true;
        this.mostrarBotones = true;
        this.validandoDocumento = false;
        this.leyendoArchivo = false;
      } else {
        this.close();
        setTimeout(() => {
          this.procesoDeCreacion = true;
        }, 200);
      }
    },
    /**
     * Descarga el resultado de las validaciones como un archivo CSV.
     */
    descargarResultadoDeValidaciones() {
      const csvContent = this.generarCSV();
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'validaciones_homologacion_productos.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    /**
     * Genera el contenido del archivo CSV a partir de los registros y sus validaciones.
     * @returns {string} - El contenido del archivo CSV.
     */
    generarCSV() {
      const eliminarTildes = (texto) => {
        return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };
      const limpiarMensaje = (mensaje) => {
        return mensaje.replace(/\n/g, ', ');
      };

      let csv = 'Codigo de cliente;Codigo de producto de Stone;Codigo del producto del cliente;Nombre del producto del cliente;Validacion\n';

      // Descarga solo los registros con error en las validaciones 
      this.registros.forEach((registro, index) => {
        if (this.validaciones[index] && this.validaciones[index].length > 0) {
          const codClie = registro.codClie ? registro.codClie : '';
          const codProd = registro.codProd ? registro.codProd : '';
          const prodClie = registro.prodClie ? registro.prodClie : '';
          // const sustituto = registro.sustituto ? registro.sustituto : '';
          const nomPrCli = registro.nomPrCli ? registro.nomPrCli : '';
          const validaciones = this.validaciones[index]
            .map(mensaje => limpiarMensaje(eliminarTildes(mensaje)))
            .join(', ');

          csv += `${codClie};${codProd};${prodClie};${nomPrCli};${validaciones}\n`;
        }
      });
      return csv;
    },
    /**
     * Reinicia el estado del componente para volver a cargar un nuevo documento CSV.
     * Este método se encarga de reiniciar todas las variables de estado relacionadas con la
     * carga de documentos y las validaciones de manera que se pueda seleccionar y cargar
     * un nuevo archivo CSV para ser procesado nuevamente.
     */
    volverACargarDocumento() {
      this.validaciones = [];
      this.registros = [];
      this.archivoSubido = false;
      this.filasSeleccionadas = [];
      this.validandoDocumento = false;
      this.formatoIncorrecto = false;
      this.verCSV = true;
      this.mostrarBotones = false;
      this.tabla = false;
      if (this.$refs.cargaDeRelaciones) {
        this.$refs.cargaDeRelaciones.value = '';
      }
    },
    /**
     * Restablece los valores de los campos y las variables relacionadas a la creación de un registro.
     * Establece los valores predeterminados y cierra los cuadros de diálogo abiertos.
     * Reinicia el estado de edición y activación de campos.
     * Reinicia el estado del componente de observación (refs.observer).
     */
    close() {
      this.resettingForm = true;
      this.dialog = false;
      this.datosExistentesError = "";
      this.estadoCliente = "";
      this.botonDisabled = true;
      this.homologacionCodClie = null;
      this.homologacionProdClie = null;
      this.homologacionProdClie2 = null;
      this.homologacionNomProdClie = null;
      this.noHayResultadoInterno = false;
      this.filtroNombre = null;
      this.filtroCodigo = null;
      this.filtroDCI = null;
      this.productoSeleccionado = [];
      this.valorSeleccionado = null;
      this.seleccionAnterior = [];
      this.opciones = 0;
      this.$refs.archivoSubido?.reset();
      this.$refs.nuevaRelacion?.reset();
      this.validaciones = [];
      this.registros = [];
      this.archivoSubido = false;
      this.filasSeleccionadas = [];
      this.validandoDocumento = false;
      this.formatoIncorrecto = false;
      this.verCSV = true;
      this.mostrarBotones = false;
      this.tabla = false;
      this.leyendoArchivo = false;
      this.mensajeIncorrecto = '';
      if (this.$refs.cargaDeRelaciones) {
        this.$refs.cargaDeRelaciones.value = '';
      }
      this.$nextTick(() => {
        this.resettingForm = false;
      });
    },
    /**
     * Cierra la notificación
     */
    cerrarNotificacion() {
      this.mostrarNotificacion = false;
      this.mensajeNotificacion = "";
      this.pathNotificacion = "";
      this.tipoNotificacion = "";
    },
    /**
     * Muestra el error en una notificación correspondiente.
     * 
     * @param {*} error - Objeto de error.
     */
    verError(error) {
        
      let errorMessage = "Error desconocido, contacta con el administrador.";
      let errorPath = "";

      if (error.response && error.response.data) {
        if (error.response.data.mensaje && Array.isArray(error.response.data.mensaje)) {
          errorMessage = error.response.data.mensaje[0];
        } else if (error.response.data.error) {
          errorMessage = error.response.data.error;
        }
        if (error.response.data.path) {
          errorPath = error.response.data.path;
        }
      }
      this.pathNotificacion = errorPath;
      this.mensajeNotificacion = errorMessage;
      this.mostrarNotificacion = true;

      // Cierra la notificación después de 5 segundos
      setTimeout(() => {
        this.cerrarNotificacion();
      }, 5000);
    },
  },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}
::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
::v-deep .elevation .v-data-footer {
  width: 100%;
}
::v-deep .elevation .v-data-footer__select .v-select {
  margin: 5px;
  margin-left: 10px;
}
::v-deep .elevation .v-data-table__wrapper {
  border: 1px solid #f7f6f6;
}
.fondoDialog {
  background-color: #1867c0;
  color: rgb(223, 223, 223) !important;
}
.mensaje {
  text-align: center;
  padding-top: 120px !important;
}
.mensaje em {
  font-size: larger;
  color: rgb(161 161 162);
}
.mensaje:hover {
  background-color: white;
}
.icono {
  font-size: 50px;
}
.error-texto {
  color: #ff5252;
  font-size: smaller;
}
.mensaje-error {
  margin-top: 0px;
  padding: 0px 0px;
}
::v-deep .v-tabs .v-tabs-slider-wrapper {
  top: 80% !important;
}
::v-deep .v-tabs-slider {
  width: 100% !important;
}
::v-deep .tablaRelaciones div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}
.header tr {
  background-color: rgb(223, 223, 223) !important;
}
.header tr th {
  font-weight: bold;
  color: black !important;
}
.archivo {
  outline: 2px dashed grey;
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 1rem;
  height: 100px;
  position: relative;
  cursor: default;
}
.archivo p {
  margin-top: .7rem;
  text-align: center;
}
.archivo >div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.seleccionarArchivo {
  display: flex;
  justify-content: center;
  opacity: 0;
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}
.seleccionarArchivo:disabled {
  cursor: default;
}
.error-message {
  color: red;
  white-space: pre-line;
}
.rotate-animation {
  animation: rotate 2s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.resultadoLista {
  max-height: 12rem;
  overflow-y: auto;
  z-index: 999;
  width: 100%;
}
::v-deep .estiloFilas div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}
::v-deep .estiloFilas div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
.estiloFilas tbody tr td {
  font-size: 14px !important;
}
::v-deep .estiloFilas .v-data-footer {
  width: 100% !important;
}
::v-deep .estiloFilas .v-data-footer__select .v-select {
  margin: 5px;
  margin-left: 10px;
}
::v-deep .estiloFilas .v-data-table__wrapper {
  border: 1px solid #f7f6f6;
}
.mensaje-interno {
  text-align: center;
  padding-top: 30px !important;
}
.mensaje-interno em {
  font-size: medium;
  color: rgb(161 161 162);
}
.mensaje-interno:hover {
  background-color: white;
}
.notificationValidationEstado {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translateX(-50%);
  background-color: #5baa5e;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.ancho-estado {
  width: 38vw;
}
.ancho-creacion {
  width: 28vw;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.46);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
}
.notificationValidation {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translateX(-50%);
  background-color: #5baa5e;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 15vw;
}
.notificacion {
  position: fixed;
  top: 50px;
  right: 20px;
  padding: 15px;
  border-radius: 5px;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
  max-width: 30vw;
}
.notificacion span:last-child {
  cursor: pointer;
  margin-right: 10px;
  padding-top: 3px;
}
.rotate-animation-notification {
  animation: rotate-notification 1s ease-in-out; 
}
@keyframes rotate-notification  {
  0% {
    transform: rotateX(180deg);
  }

  50% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(180deg);
  }
}
.size {
  font-size: xxx-large;
  font-weight: bold;
  color: #f80505;
}
.advertencia {
  background-color: #ffffffd7 !important;
  color: #f80505;
}
::v-deep .v-text-field .v-text-field__details {
  margin: 0;
}
</style>